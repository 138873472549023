:root {
    --primary-orange: #ff7321;
    --primary-black: #0f172b;
    --primary-dark-blue: #0f334a;
    --secondary-light-blue: #99b7c4;
    --secondary-white: #ffffff;
    --secondary-grey: #cccccc;
}

html {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-y: scroll;
}

body {
    min-height: 100%;
    margin: 0;
    padding: 0;
    background-color: var(--color-background);
}

button {
    padding: 0;
    border: none;
    background-color: transparent;
}

input[type='checkbox'],
input[type='radio'] {
    transform: scale(1.1);
}

.banner {
    display: table;
    background-image: url("../public/img/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: contain;
    height: 96px;
    width: 100%;
    margin-bottom: 24px;
    padding: 0;
    border-radius: 5px;
}

.gridBanner {
    display: table;
    background-image: url("../public/img/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: contain;
    height: 96px;
    width: 100%;
    margin-bottom: -1px;
    padding: 0;
    border-radius: 5px 5px 0 0;
}

.bannerText {
    color: var(--secondary-white);
    display: table-cell;
    vertical-align: middle;
    margin: auto;
    padding: 10px;
}

.logoBackground {
    position: relative;
    width: 210px;
    min-width: 210px;
    height: 100%;
    padding: 0;
    margin: 0;
    left: -24px;
    text-align: center;
    background-color: var(--secondary-white);
}

.logoBackground:after {
    display: flex;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: var(--secondary-white);

    transform-origin: bottom left;
    -ms-transform: skew(-30deg, 0deg);
    -webkit-transform: skew(-30deg, 0deg);
    transform: skew(-30deg, 0deg);
}

.appbar {
    box-sizing: border-box;
    min-width: 1290px;
    margin-bottom: 26px;
    padding: 0;
}

.footer {
  background-color: var(--secondary-white);
  position: fixed;
  bottom: 0;
  left: 0;
}

.toolbar {
    height: 52px;
    box-shadow: 1px 3px 7px var(--secondary-grey);
    padding: 0;
    margin: 0;
    min-width: 1300px;
    background-color: var(--primary-black);
    z-index: 1;
}

.backToTopParent {
    overflow: hidden;
}

.backToTop {
    background-color: var(--primary-dark-blue);
    box-shadow: 1px 3px 7px var(--secondary-grey);
    position: fixed;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin: 10px;
    bottom: 70px;
    cursor: pointer;
}

.stack {
    margin: 0 0 0 24px;
    padding: 0;
}

.appbarImgParent {
    float: left;
    width: 100%;
    height: 100%;
    margin: -9px 0 0 0px;
    padding: 0;
}

.appbarImg {
    height: 38px;
    width: auto;
    margin: -2px 10px 0 0;
    padding: 0;
}

.clearBoth {
    clear: both;
}

.homePageDescription {
    border-left: 3px solid;
    border-color: var(--primary-orange);
    padding-left: 15px;
    margin: 7px;
    min-width: 560px;
}

.homePageList {
    list-style-type: circle;
    padding-left: 32px;
}


.homePageFlexContainer {
    display: flex;
    flex-wrap: wrap;
}

.homePageFlexItem {
    flex: 1 1 50%;
}

.listIconTouch {
    list-style-image: url("../public/img/touchscreen.svg");
    font-size: 36px;
}

.listIconTouch > a {
    font-size: initial;
    vertical-align: 10%;
}

.listIconLight {
    list-style-image: url("../public/img/brainstorming.svg");
    font-size: 36px;
}

.listIconLight > a {
    font-size: initial;
    vertical-align: 10%;
}

.genericLink:link,
.genericLink:visited {
    color: black;
    text-decoration: none;
    border-bottom: 1px solid black;
}

.genericLink:hover,
.genericLink:active {
    color: black;
    text-decoration: none;
    border-bottom: 2px solid var(--primary-orange);
}

.dataGridLink:link {
    color: black;
    text-decoration: none;
    border-bottom: 1px solid black;
}

.viewAssetContainer {
    display: flex;
}

.viewAssetBox {
    border-radius: 5px;
    box-shadow: 1px 3px 7px var(--secondary-grey);
    padding: 15px;
    margin: 7px;
    min-width: 580px;
}

.applicationLinksBox {
    border-radius: 5px;
    box-shadow: 1px 3px 7px var(--secondary-grey);
    padding: 15px;
    margin: 7px;
    min-width: 200px;
}

.tabContainer {
    border-radius: 5px;
    padding: 15px;
    margin: 7px;
    min-width: 580px;
}

.tabBar {
    background-color: var(--primary-black);
    border-radius: 5px;
    padding: 15px;
    margin: 7px;
    min-width: 580px;
}

.instSearchBox {
    min-width: 580px;
    width: 700px;
}

.dropDownList {
    border-radius: 5px;
    box-shadow: 1px 3px 7px var(--secondary-grey);
    margin: 0 1px 7px 1px;
    width: 700px;
    max-height: 610px;
    overflow: auto;
}

.dropDownItem:hover {
    background-color: #eeeeee;
    cursor: pointer;
}

.assetsTextTrue {
    color: var(--primary-orange)
}

.assetsTextFalse {
    color: #000000
}

.navigationButton:hover {
    background-color: #1f273b;
}

.avatarHolder {
    margin: 0;
    padding: 0;
    float: right;
}

.dialogButtonBar {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
}

.gridBlackHeader {
    background-color: var(--primary-black);
}

.MuiDataGrid-columnHeaders {
    background-color: var(--primary-black);
    --unstable_DataGrid-radius: 0;
}

input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 10px;
    width: 10px;
    background-image: url("../public/img/close.svg");
}

.auth {
  &__screen {
    height: 100vh;
    width: 100vw;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &__error-title {
    font-weight: 600;
    margin-bottom: 1rem;
  }

  &__error-message {
    padding: 1rem;
    max-width: 600px;

    font-size: 0.872rem;
  }
}

.content {
  display: flex;
  margin: 0 auto;

  color: var(--color-text);
  padding: 2rem 1.5rem 4rem 1.5rem;

  &__sidebar {
    width: 300px;
    margin-right: 1rem;
  }

  &__main {
    flex: 1;
  }
}

.modal .modal-content {
  max-width: 800px;
}

.subscriptions-modal .modal-content {
  max-width: 1280px;
  padding: 0.75rem;
}

.subscriptions {
  &__title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 1.5rem;
  }

  &__roles {
    user-select: none;
  }

  &__roles-input {
    display: flex;
    margin: 0.25rem 0;
    align-items: center;

    span {
      padding-top: 2px;
    }

    input {
      margin-right: 0.5rem;
    }
  }

  &__categories {
    display: flex;
  }

  &__category {
    display: flex;
    align-items: center;
    padding-right: 1rem;

    span {
      padding-left: 0.5rem;
    }
  }

  &__tabs {
    max-width: 80%;
    font-size: 0.8rem;
  }

  &__new {
    display: flex;
    margin-bottom: 0.5rem;
  }

  &__name {
    width: 270px;
    margin-right: 0.5rem;
  }

  &__frequency {
    width: 150px;
  }

  &__filters-title {
    padding: 0.5rem 0;
    font-weight: 500;
  }

  &__filters {
    gap: 2rem;
    display: grid;
    margin-bottom: 0.5rem;

    grid-template-columns: 1fr 1fr;

    .react-checkbox-tree ol {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .rct-title {
      white-space: nowrap;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__general-buttons > button {
    margin-right: 0.5rem;
  }
}
